

























































































































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Config as VuescrollConfig } from 'vuescroll'
import DashboardHeaderListItem from '@/components/DashboardHeader/DashboardHeaderListItem.vue'
import MediaList from '@/components/MediaList/MediaList.vue'
import { FleetHandler, Vehicle, VehicleObject } from '@/data/fleet'
import AddVehicleForm from '@/components/forms/AddVehicleForm.vue'
import AddVehicleCard from '@/components/vehicles/AddVehicleCard.vue'
import EditVehicleForm from '@/components/forms/EditVehicleForm.vue'
import UploadProfileImageForm from '@/components/forms/UploadProfileImageForm.vue'
import { ResponseError } from '@/data/error'
import { IMediaListItem } from '@/components/MediaList/lib/MediaList'
import moment from 'moment'

// const fleetModule = namespace('fleet')
const authenticationModule = namespace('authentication')

@Component({
  components: {
    DashboardHeaderListItem,
    MediaList,
    AddVehicleCard,
    AddVehicleForm,
    EditVehicleForm,
    UploadProfileImageForm
  }
})
export default class FleetVueComponent extends Vue {
  private fleetHandler: FleetHandler = new FleetHandler()
  public selectedVehicleKey: string | null = null
  public showDeleteConfirmation = false
  public showDeleteImageConfirmation = false
  public isSensorCardHovered = false
  public vehiclesDbReference = FleetHandler.REF_VEHICLES

  /**
   * VueScroll options for the DashboardHeader in vertical direction.
   */
  public vueScrollOptions: VuescrollConfig = {
    vuescroll: {
      sizeStrategy: 'number'
    },
    scrollPanel: {
      speed: 300,
      scrollingY: false
    }
  }

  @authenticationModule.State('isRelatedConnected') isRelatedConnected!: boolean

  get fleet (): Array<Vehicle> {
    return this.fleetHandler.fleet
  }

  get selectedVehicle (): VehicleObject | null {
    if (this.selectedVehicleKey) {
      const index = this.fleetHandler.fleet.map(vehicle => vehicle.key).indexOf(this.selectedVehicleKey)
      if (index > -1) {
        return this.fleetHandler.fleet[index]
      }
    }
    return null
  }

  get selectedVehicleToursMediaItem (): IMediaListItem[] {
    const tourItems: IMediaListItem[] = []
    if (this.selectedVehicle?.tours) {
      for (let i = 0; i < this.selectedVehicle.tours.length; i++) {
        const tour = this.selectedVehicle.tours[i]
        tourItems.push({
          key: tour.key,
          title: tour.number,
          subtitle: moment(tour.departureDate).format('DD.MM.YYYY HH:mm:ss'),
          icon: 'clipboard',
          variant: 'warning'
        })
      }
    }
    return tourItems
  }

  get selectedVehicleSensorsMediaItem (): IMediaListItem[] {
    const sensorItems: IMediaListItem[] = []
    if (this.selectedVehicle?.sensors) {
      for (let i = 0; i < this.selectedVehicle.sensors.length; i++) {
        const sensor = this.selectedVehicle.sensors[i]
        sensorItems.push({
          key: sensor.key,
          title: sensor.name,
          subtitle: sensor.model,
          caption: sensor.position ? `Position: ${sensor.position}` : undefined,
          icon: 'broadcast-pin',
          variant: 'info'
        })
      }
    }
    return sensorItems
  }

  get selectedVehicleDriversMediaItem (): IMediaListItem[] {
    const driverItems: IMediaListItem[] = []
    if (this.selectedVehicle?.drivers) {
      for (let i = 0; i < this.selectedVehicle.drivers.length; i++) {
        const driver = this.selectedVehicle.drivers[i]
        driverItems.push({
          key: driver.key,
          title: driver.name,
          subtitle: driver.date,
          variant: 'success'
        })
      }
    }
    return driverItems
  }

  /**
   * Display content of the create form by resetting the vehicle selection.
   */
  public showCreateForm (): void {
    this.selectedVehicleKey = null
  }

  public selectVehicle (vehicle: Vehicle): void {
    this.showDeleteConfirmation = false
    this.showDeleteImageConfirmation = false
    this.selectedVehicleKey = vehicle.key
  }

  public showDeleteDialog (): void {
    this.showDeleteConfirmation = true
  }

  public onDeleteDialogShown (): void {
    const deleteDialog = this.$refs.deleteDialog as HTMLElement | null
    if (deleteDialog) {
      deleteDialog.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }

  public onDeleteDialogHidden (): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  public onDeleteCancel (): void {
    this.showDeleteConfirmation = false
  }

  public onDeleteConfirm (): void {
    // this.isLoading = true
    if (this.selectedVehicle) {
      FleetHandler.removeVehicle(this.selectedVehicle.key).then(() => {
        this.$root.$bvToast.toast(this.$tc('databaseResults.removeVehicle.success'), {
          title: this.$tc('databaseResults.removeVehicle.title'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'success'
        })
      }).catch((error) => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
        this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
          title: this.$tc('databaseResults.editVehicle.title'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'danger'
        })
      }).finally(() => {
        this.showDeleteConfirmation = false
        this.showCreateForm()
        // this.isLoading = false
      })
      // this.isLoading = false
    } else {
      this.$root.$bvToast.toast(this.$tc('databaseResults.removeVehicle.emptyKey'), {
        title: this.$tc('databaseResults.removeVehicle.title'),
        autoHideDelay: 15000,
        appendToast: true,
        solid: true,
        variant: 'danger'
      })
    }
  }

  public showDeleteImageDialog (): void {
    this.showDeleteImageConfirmation = true
  }

  public onDeleteImageCancel (): void {
    this.showDeleteImageConfirmation = false
  }

  public onDeleteImageConfirm (): void {
    if (this.selectedVehicle) {
      FleetHandler.removeProfileImage(this.selectedVehicle.key)
    }
    this.showDeleteImageConfirmation = false
  }

  public handleSensorCardHover (hovered: boolean): void {
    this.isSensorCardHovered = hovered
  }

  public finishedImageUpload (key: string, imageUrl: string): void {
    FleetHandler.updateVehicleProperties(key, {
      imageSrc: imageUrl,
      imageDate: FleetHandler.FIREBASE_TIMESTAMP
    }).then(() => {
      this.$root.$emit('bv::toggle::collapse', 'collapse-image-edit')
    })
  }

  public cancelledImageForm (): void {
    this.$root.$emit('bv::toggle::collapse', 'collapse-image-edit')
  }

  public mounted (): void {
    this.fleetHandler.startFleetListener()
  }

  public destroyed (): void {
    this.fleetHandler.stopFleetListener()
  }
}
