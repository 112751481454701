
































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { FleetHandler, Vehicle } from '@/data/fleet'
import FormCheck from '@/common/formCheck'
import { ResponseError } from '@/data/error'

@Component
export default class EditVehicleForm extends Vue {
  @Prop() selectedVehicle!: Vehicle

  public formCheck: FormCheck = new FormCheck()
  public isLoading = false
  public responseError: string | null = null
  public showResponseAlert = false
  public initialVehicle: Vehicle = { ...this.selectedVehicle }
  public vehicle: Vehicle = { ...this.selectedVehicle }
  public hasChanges = false

  get stateMake (): false | null {
    return this.formCheck.findFormError('make') ? false : null
  }

  get stateModel (): false | null {
    return this.formCheck.findFormError('model') ? false : null
  }

  get statePlateNumber (): false | null {
    return this.formCheck.findFormError('plateNumber') ? false : null
  }

  /**
   * Submit the add vehicle form.
   */
  public submitEditVehicle (): void {
    this.isLoading = true
    if (!this.checkForm()) {
      FleetHandler.updateVehicleProperties(this.vehicle.key, {
        make: this.vehicle.make,
        model: this.vehicle.model,
        plateNumber: this.vehicle.plateNumber,
        features: this.vehicle.features.join(';')
      }).then(() => {
        this.$root.$bvToast.toast(this.$tc('databaseResults.editVehicle.success', 0, { plateNumber: this.vehicle.plateNumber }), {
          title: this.$tc('databaseResults.editVehicle.title'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'success'
        })
      }).catch((error) => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
        this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
          title: this.$tc('databaseResults.editVehicle.title'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'danger'
        })
      }).finally(() => {
        this.isLoading = false
        this.hasChanges = false
      })
    } else {
      this.isLoading = false
    }
  }

  /**
   * Check the form input against rules before submitting.
   */
  public checkForm (): boolean {
    this.formCheck.clearFormErrors()
    this.formCheck.checkTextRequired('make', this.vehicle.make)
    this.formCheck.checkTextRequired('model', this.vehicle.model)
    this.formCheck.checkTextRequired('plateNumber', this.vehicle.plateNumber)
    this.formCheck.checkPlateNumber('plateNumber', this.vehicle.plateNumber)
    return this.formCheck.hasErrors()
  }

  /**
   * Format plate numbers to be upper case.
   */
  public plateNumberFormatter (value: string): string {
    return value.toUpperCase()
  }

  /**
   * Reset all form errors and form inputs.
   */
  public resetForm (): void {
    this.vehicle = { ...this.initialVehicle }
    this.hasChanges = false
    this.formCheck.clearFormErrors()
  }

  /**
   * This methods sets the `hasChanges` property to `true`.
   */
  public setHasChanges (): void {
    this.hasChanges = true
  }

  /**
   * Clear the states' error when the alert is dismissed.
   */
  public dismissedResponseAlert (): void {
    this.responseError = null
  }
}
