





























































































































import { Component, Vue } from 'vue-property-decorator'
import { AddVehicleFormObject, FleetHandler } from '@/data/fleet'
import FormCheck from '@/common/formCheck'
import { ResponseError } from '@/data/error'

@Component
export default class AddVehicleForm extends Vue {
  public formCheck: FormCheck = new FormCheck()
  public isLoading = false
  public responseError: string | null = null
  public showResponseAlert = false
  public vehicle: AddVehicleFormObject = {
    make: '',
    model: '',
    plateNumber: '',
    featureList: []
  }

  get stateMake (): false | null {
    return this.formCheck.findFormError('make') ? false : null
  }

  get stateModel (): false | null {
    return this.formCheck.findFormError('model') ? false : null
  }

  get statePlateNumber (): false | null {
    return this.formCheck.findFormError('plateNumber') ? false : null
  }

  /**
   * Submit the add vehicle form.
   */
  public submitAddVehicle (): void {
    this.isLoading = true
    if (!this.checkForm()) {
      FleetHandler.createVehicle(this.vehicle).then(() => {
        this.$root.$bvToast.toast(this.$tc('databaseResults.addVehicle.success', 0, { plateNumber: this.vehicle.plateNumber }), {
          title: this.$tc('databaseResults.addVehicle.title'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'success'
        })
        this.resetForm()
      }).catch((error) => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
        this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
          title: this.$tc('databaseResults.addVehicle.title'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'danger'
        })
      }).finally(() => {
        this.isLoading = false
      })
    }
    this.isLoading = false
  }

  /**
   * Check the form input against rules before submitting.
   */
  public checkForm (): boolean {
    this.formCheck.clearFormErrors()
    this.formCheck.checkTextRequired('make', this.vehicle.make)
    this.formCheck.checkTextRequired('model', this.vehicle.model)
    this.formCheck.checkTextRequired('plateNumber', this.vehicle.plateNumber)
    this.formCheck.checkPlateNumber('plateNumber', this.vehicle.plateNumber)
    return this.formCheck.hasErrors()
  }

  /**
   * Format plate numbers to be upper case.
   */
  public plateNumberFormatter (value: string): string {
    return value.toUpperCase()
  }

  /**
   * Reset all form errors and form inputs.
   */
  public resetForm (): void {
    this.vehicle = {
      make: '',
      model: '',
      plateNumber: '',
      featureList: []
    }
    this.formCheck.clearFormErrors()
  }

  /**
   * Clear the states' error when the alert is dismissed.
   */
  public dismissedResponseAlert (): void {
    this.responseError = null
  }
}
