import constants from '@/common/constants'
import { getDatabase, DatabaseReference, serverTimestamp, ref, onChildAdded, onChildChanged, onChildRemoved, child, update, off, push, increment, remove, DataSnapshot } from 'firebase/database'
import { getStorage, ref as stRef, UploadMetadata, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import { FirebaseAppInstance } from '@/data/app'
import { VehicleObject, VehicleTour } from './vehicle'
import { SensorObject } from '@/data/sensor'
import { AddVehicleFormObject } from '..'

/**
 * Object interface to create a new vehicle in the firebase database.
 */
interface DbAddVehicle extends AddVehicleFormObject {
  features: string
  // eslint-disable-next-line
  created: any
}

export class FleetHandler {
  private vehiclesRef: DatabaseReference
  public fleet: Array<VehicleObject>
  public static readonly REF_VEHICLES: string = 'vehicles'
  public static readonly FIREBASE_TIMESTAMP = serverTimestamp()

  constructor () {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    this.vehiclesRef = ref(db, constants.DB_VEHICLES)
    this.fleet = []
  }

  public startFleetListener (): void {
    // Child added
    onChildAdded(this.vehiclesRef, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        const vehicle = this.convertVehicleSnapshotToVehicle(snapshot, snapshot.key)
        this.fleet.push(vehicle)
      }
    })

    // Child changed
    onChildChanged(this.vehiclesRef, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        const index = this.fleet.map(vehicle => vehicle.key).indexOf(snapshot.key)
        if (index > -1) {
          const newVehicle = this.convertVehicleSnapshotToVehicle(snapshot, snapshot.key)
          this.fleet.splice(index, 1, newVehicle)
        }
      }
    })

    // Child removed
    onChildRemoved(this.vehiclesRef, (snapshot) => {
      if (snapshot.key) {
        const index = this.fleet.map(vehicle => vehicle.key).indexOf(snapshot.key)
        if (index > -1) {
          this.fleet.splice(index, 1)
        }
      }
    })
  }

  public stopFleetListener (): void {
    off(this.vehiclesRef)
  }

  public static async createVehicle (vehicle: AddVehicleFormObject): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const vehiclesRef = ref(db)
    const newPostKey = push(child(vehiclesRef, constants.DB_VEHICLES)).key

    const createVehicle = vehicle as DbAddVehicle
    createVehicle.features = vehicle.featureList?.join(';') ?? ''
    createVehicle.featureList = null
    createVehicle.created = serverTimestamp()
    // const createVehicle: DbAddVehicle = {
    //   make: vehicle.make,
    //   model: vehicle.model,
    //   plateNumber: vehicle.plateNumber,
    //   features: vehicle.featureList?.join(';') ?? '',
    //   featureList: null,
    //   created: serverTimestamp()
    // }

    // eslint-disable-next-line
    const updates: Record<string, any> = {}
    updates['/vehicles/' + newPostKey] = createVehicle
    updates['/statistics/vehicles/count'] = increment(1)

    await update(vehiclesRef, updates)
    return Promise.resolve()
  }

  /**
   * Update single properties on the vehicle specified by the `key` attribute
   * @param key The vehicle-specific database key.
   * @param data The comma-separated properties to be updated. `{'key': 'value'}`
   * @returns Promise
   */
  // eslint-disable-next-line
  public static async updateVehicleProperties (key: string, data: Record<string, any>): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const vehiclesRef = ref(db, constants.DB_VEHICLES)

    await update(child(vehiclesRef, key), {
      ...data,
      modified: serverTimestamp()
    })
    return Promise.resolve()
  }

  public static async updateVehicleProfileImage (key: string, imageUrl: string): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const vehiclesRef = ref(db, constants.DB_VEHICLES)

    await update(child(vehiclesRef, key), {
      imageSrc: imageUrl,
      imageDate: serverTimestamp()
    })
    return Promise.resolve()
  }

  public static async removeVehicle (key: string): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const vehiclesRef = ref(db, constants.DB_VEHICLES)

    await remove(child(vehiclesRef, key))
    return Promise.resolve()
  }

  public static async uploadProfileImage (key: string, image: File): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    // const db = getDatabase(app)
    const st = getStorage(app)
    // const vehiclesRef = ref(db, constants.DB_VEHICLES)
    const storageRef = stRef(st, constants.ST_VEHICLES)

    const metadata: UploadMetadata = {
      contentType: image.type
    }

    // const uploadTask = storageRef.child(key).put(image, metadata)
    const uploadTask = uploadBytesResumable(stRef(storageRef, key), image, metadata)

    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      console.log(progress)
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused')
          break
        case 'running':
          console.log('Upload is running')
          break
      }
    }, (error) => {
      switch (error.code) {
        case 'storage/unauthorized':
          console.log('not authorized')
          break
        case 'storage/canceled':
          console.log('canceled')
          break
        case 'storage/unknown':
          console.log('unknown storage')
          break
      }
    }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at: ', downloadURL)
        // TODOS:
        // tour_id & driver_id: /vehicles/$vehicle_id/tours/[$tour_id]/driver
        // UPDATE: /users/$driver_id/tours/$tour_id/vehicle/image => downloadURL
        // UPDATE: /users/$driver_id/tours/$tour_id/vehicle/imageDate => firebase.database.ServerValue.TIMESTAMP
        //

        // for (const tour in selectedVehicle.tours) {
        //   if ( selectedVehicle.tours.hasOwnProperty( tour ) ) {
        //     const dict = selectedVehicle.tours[tour];
        //     firebase.database().ref( '/users/' + dict.driver + '/tours/' + dict.tour + '/vehicle/' ).update({
        //         image: downloadURL ? downloadURL : null,
        //         imageDate: firebase.database.ServerValue.TIMESTAMP,
        //     }, function ( error ) {
        //         if ( error ) {
        //             _self._showErrorNotification( error.message );
        //         }
        //     });
        //   }
        // }

        // update(child(vehiclesRef, key), {
        //   imageSrc: downloadURL,
        //   imageDate: serverTimestamp()
        // }).catch((error) => {
        //   if (error) {
        //     console.log(error)
        //   }
        // })
      })
    })
  }

  public static async removeProfileImage (key: string): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const st = getStorage(app)
    const vehiclesRef = ref(db, constants.DB_VEHICLES)
    const storageRef = stRef(st, constants.ST_VEHICLES)

    // for (const tour in selectedVehicle.tours) {
    //   if ( selectedVehicle.tours.hasOwnProperty( tour ) ) {
    //     const dict = selectedVehicle.tours[tour];
    //     firebase.database().ref( '/users/' + dict.driver + '/tours/' + dict.tour + '/vehicle/' ).update({
    //         image: null,
    //         imageDate: firebase.database.ServerValue.TIMESTAMP,
    //     }, function ( error ) {
    //         if ( error ) {
    //             _self._showErrorNotification( error.message );
    //         }
    //     });
    //   }
    // }

    deleteObject(stRef(storageRef, key)).then(() => {
      update(child(vehiclesRef, key), {
        imageSrc: null,
        imageDate: null
      }).catch((error) => {
        if (error) {
          console.log(error)
        }
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  /**
   * Convert a firebase `DataSnapshot` to a VehicleObject.
   *
   * @param snapshot The firebase snapshot.
   * @param key The key of the snapshot.
   * @returns The converted VehicleObject.
   */
  private convertVehicleSnapshotToVehicle (snapshot: DataSnapshot, key: string): VehicleObject {
    const featuresString = snapshot.val().features as string | undefined
    const featureList = this.convertFeatureStringToList(featuresString)

    const snapshortTours = snapshot.val().tours
    const vehicleTours: VehicleTour[] = this.convertDbToursToVehicleTours(snapshortTours)

    const snapshortSensors = snapshot.val().sensors
    const vehicleSensors: SensorObject[] = this.convertDbSensorsToVehicleSensors(snapshortSensors)

    const vehicle = snapshot.val() as VehicleObject
    vehicle.key = key
    vehicle.features = featureList
    vehicle.tours = vehicleTours
    vehicle.sensors = vehicleSensors
    return vehicle
  }

  private convertFeatureStringToList (featuresString?: string): string[] {
    const featureList: string[] = []
    if (featuresString) {
      const tempFeatureList = featuresString.split(';')
      for (const index in tempFeatureList) {
        const trimmedFeature = tempFeatureList[index].trim()
        if (trimmedFeature.length > 0) {
          featureList.push(trimmedFeature)
        }
      }
    }
    return featureList
  }

  // eslint-disable-next-line
  private convertDbToursToVehicleTours (dbTours: any): VehicleTour[] {
    const vehicleTours: VehicleTour[] = []
    if (dbTours) {
      const toursArray = Object.keys(dbTours).map((key) => {
        dbTours[key].tour = key
        return dbTours[key]
      })
      for (const index in toursArray) {
        if (Number(index) >= 5) {
          break
        }
        const vehicleTour: VehicleTour = {
          key: toursArray[index].tour,
          number: toursArray[index].number,
          departureDate: toursArray[index].departureDate
        }
        vehicleTours.push(vehicleTour)
      }
    }
    return vehicleTours
  }

  // eslint-disable-next-line
  private convertDbSensorsToVehicleSensors (dbSensors: any): Array<SensorObject> {
    const vehicleSensors: Array<SensorObject> = []
    if (dbSensors) {
      const sensorsArray = Object.keys(dbSensors).map((key) => {
        dbSensors[key].tour = key
        return dbSensors[key]
      })
      for (const index in sensorsArray) {
        const vehicleSensor: SensorObject = {
          key: sensorsArray[index].tour,
          datalogger: sensorsArray[index].datalogger,
          firmware: sensorsArray[index].firmware,
          logInterval: sensorsArray[index].logInterval,
          model: sensorsArray[index].model,
          name: sensorsArray[index].name,
          password: sensorsArray[index].password,
          position: sensorsArray[index].position,
          serialKey: 'SOME KEY',
          created: 0
        }
        vehicleSensors.push(vehicleSensor)
      }
    }
    return vehicleSensors
  }
}
