














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DashboardHeaderListItem extends Vue {
  @Prop(String) subtitle!: string
  @Prop(String) imageSrc!: string
  @Prop({ default: 'truck', type: String }) icon!: string
  @Prop({ default: 'light', type: String }) variant!: string
}
